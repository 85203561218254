
export function getInternalLinks(){
    let links = Array.from(document.querySelectorAll('a'));
    return links.filter(link => isSamePageLink(link.href, window.location.href));
}

export function getHashFromUrl(url: string){
    if(url.includes('#')){
        //check if url has params
        if(url.includes('?')){
            //remove params
            url = url.split('?')[0];
        }
        return url.split('#')[1];
    }
    return '';
}

export function isSamePageLink(link: string, currentUrl: string){
    if(link.startsWith('/')){
        link = link.slice(1)
    }
    if(link.startsWith('#')){
        return true
    }
    try {

    const url = new URL(link);
    const current = new URL(currentUrl);
    const sameDomain = url.hostname === current.hostname;
    const samePath = url.pathname === current.pathname;
    // we might have a link without a domain, but with a hash, if so lets set the domain to the current domain
    if(!url.hostname){
        url.hostname = current.hostname;
    }
    // we dont care about matching the hash, only that it exists
    const hashExists = url.hash !== '';
    return sameDomain && samePath && hashExists;
    } catch (error) {
        return false;
    }
}

