import { gsap } from "gsap";
import {ScenesManager,Scene} from "./SceneManager";
import {getHashFromUrl, getInternalLinks} from "./utils";
import {menuTimeLineController} from "./global";
import {auroraBAnimation} from "./gsapSetups";

const MAX_MOBILE_WIDTH = 1200;


function createClientsBg() {
    const base = "#clients .bg"
    const tl = gsap.timeline({
        paused: true,
        defaults:{
            duration: 20,
            ease: "none",
        },
        repeat: -1,
        yoyo: true,
        yoyoEase: "none",
    })
        .to(base + " .right-1",{
            rotate: 360,
            x: "-50%",
            y: "90%",
        })
        .to(base + " .right-2",{
            rotate: -360,
            x: "15%",
            y: "70%",
        }, "<")
        .to(base + " .right-3",{
            rotate: 360,
            x: "-10%",
            y: "80%",
        }, "<")
        .to(base + " .left-1",{rotate: 360, x: "15%", y: "100%",}, "<")
        .to(base + " .left-2",{rotate: -360, x: "10%", y: "100%",}, "<")
    return tl;
}
// zefoLocationSlider()
function zefoLocationSlider(){

    const mainSelector = '.zefo-locations-slider';
    const mainElement = document.querySelector<HTMLElement>(mainSelector)
    if(!mainElement) {
        console.error("Cannot find zefoLocations ")
    }

    const slider = mainElement.querySelector<HTMLElement>('.slider-content')
    const sliderScrollerContainer = mainElement.querySelector<HTMLElement>('.slider-scroller')
    const scrollerHead = mainElement.querySelector<HTMLButtonElement>('.scroll-head')
    if(!scrollerHead){
        console.error("CANNOT FIND DRAG HEAD")
    }

    let isMovingScrollHead = false

    const moveListener = (clientX: number)=>{
        if(isMovingScrollHead){
           // update the scroll head position to match the x position of the mouse.
            const x = clientX - sliderScrollerContainer.offsetLeft - (scrollerHead.offsetWidth/2)
            //percentage on scroll
            const percentageOnScroll = (x/sliderScrollerContainer.offsetWidth) * 100
            //max at 99%
            if(percentageOnScroll >= 100) return
            if(percentageOnScroll < -1) return
            scrollerHead.style.left = `${percentageOnScroll}%`
            //transform slider to match the percentage.
            slider.style.transform = `translateX(${percentageOnScroll}%)`
        }
    }
    //on mouse down, or on focus, listen to movement
    scrollerHead.addEventListener('mousedown',()=>{
        isMovingScrollHead = true
    })
    document.addEventListener('mouseup',()=> {
        isMovingScrollHead = false
    })

    mainElement.addEventListener('mousemove',(e)=>{
        moveListener(e.clientX)
    })

    //add touch events
    scrollerHead.addEventListener('touchstart',()=>{
        isMovingScrollHead = true
    })
    document.addEventListener('touchend',()=> {
        isMovingScrollHead = false
    })
    mainElement.addEventListener('touchmove',(e)=>{
        const clientX = e.touches[0].clientX
        moveListener(clientX)
    })

}

function  expertiseSlider(){
    const mainSliderElement = document.querySelector<HTMLElement>('#expertise .expertise-slider')
    if(!mainSliderElement) {
        console.error("Cannot find expertise slider")
    }
    const slider = mainSliderElement.querySelector<HTMLElement>('.inner')
    const controlles = mainSliderElement.querySelectorAll<HTMLButtonElement>('.expertise-slider-controls .control-dot')

    const allSlides = Array.from(slider.querySelectorAll<HTMLElement>('.expertise-slide'))

    function changeActiveSlide(targetIndex:number){
        const activeSlide = slider.querySelector<HTMLElement>('.expertise-slide.active')
        const targetSlide = slider.querySelector<HTMLElement>(`.expertise-slide:nth-child(${targetIndex})`)
        if(activeSlide){
            activeSlide.classList.remove('active')
        }
        if(targetSlide){
            targetSlide.classList.add('active')
        }
        const activeController = mainSliderElement.querySelector<HTMLElement>('.control-dot.active')
        const targetController = mainSliderElement.querySelector<HTMLElement>(`.control-dot:nth-child(${targetIndex})`)
        if(activeController){
            activeController.classList.remove('active')
        }
        if(targetController){
            targetController.classList.add('active')
        }
    }

    controlles.forEach((c,i)=>{
        c.addEventListener('click',()=>{
            changeActiveSlide(i+1)
        })
    })

    allSlides.forEach(s=>{
        if(s.classList.contains('with-more-text')){
            s.addEventListener('click',(e)=>{
                if(s.classList.contains('clicked')){
                    s.classList.remove('clicked')
                    mainSliderElement.classList.remove('opened')
                }else{
                    allSlides.forEach(s=>s.classList.remove('clicked'))
                    s.classList.add('clicked')
                    mainSliderElement.classList.add('opened')
                }
            })
        }
    })

    mainSliderElement.addEventListener('swiped-right',()=>{
       const activeIndex = allSlides.findIndex(s=>s.classList.contains('active'))
        if(activeIndex > 0){
            changeActiveSlide((activeIndex+1) -1)
        }
    })

    mainSliderElement.addEventListener('swiped-left',()=>{
        const activeIndex = allSlides.findIndex(s=>s.classList.contains('active'))
        if(activeIndex < allSlides.length-1){
            changeActiveSlide((activeIndex+1) +1)
        }
    })
}

function home() {




    const midPointSvg = {
        duration: 0.5,
        width: "20vh",
        height: "20vh",
        ease: "power2.in"
    }

    const endPointSvg = {
        duration: .8,
        width: "500vh",
        height: "500vh",
        ease: "power2.out"
    }

    const welcomeBg = auroraBAnimation();
    function welcome() {
        const scene = new Scene('welcome',{
            onEnter:()=>{
                gsap.set("#welcome .bg",{
                    filter: "blur(80px)",
                })
                welcomeBg.play()
            },
            onExit:()=>{
                gsap.set("#welcome .bg",{
                    filter: "blur(0px)",
                })
                welcomeBg.pause()
            }
        })
        //static empty enterTimeLine
        const enterTl = scene.createTimeLine("#welcome",{})
        //add a 1ml second job.
        enterTl.to('#welcome',{
            duration: 0.01,
        })
        // now exit timeline.
        const welcomeDot = ".welcome .animation-dot svg"
        const exitTl = scene.createTimeLine('#welcome-exit',{
            isExit:true
        })
        exitTl.to(welcomeDot, midPointSvg)
            .to(welcomeDot, {
                color: "#201D1E",
              ...endPointSvg,
            })

        return scene
    }


    function intro() {
        const scene = new Scene('intro')
        const enter = scene.createTimeLine("#intro", {
            isEnter:true,
        })

            .from(".section2-left", {
                opacity: 0,
                duration: .5,
            })
            .from(".section2-right", {
                opacity: 0,
                duration: .5,
            }, "<")

        const exit = scene.createTimeLine('#intro-exit',{
            isExit:true
        })
            .to("#intro .animation-dot svg", midPointSvg)
            .to("#intro .animation-dot svg", {
                color: "#FD8730",
              ...endPointSvg
            })

        return scene
    }


    function expertise() {
        const scene  = new Scene('expertise',{
            onExit:()=>{
                //all slides to start -- remove "clicked"
                const slides = Array.from(document.querySelectorAll<HTMLElement>("#expertise .expertise-slide"))
                slides.forEach(s=>s.classList.remove('clicked'))
            }
        })
        const enter =  scene.createTimeLine("#expertise",{isEnter:true})


            .from("#expertise .section-white-title", {
                opacity: 0,
                duration: .5,
            })
            .from(".expertise-slider", {
                opacity: 0,
                duration: .5,
            }, "<")
        const exit = scene.createTimeLine('#expertise-exit',{
            isExit:true
        })
            .to("#expertise .animation-dot svg", midPointSvg)
            .to("#expertise .animation-dot svg", endPointSvg)


        return scene
    }


    function projects() {

        function setProjectHeight(){
            //basically the height is equal to the height of .projects-slideshow boundingRec height.
            // we just need to set the var --height on it.
            const slideShow = document.querySelector<HTMLElement>("#projects .projects-slideshow")
            if(slideShow){
                const height = slideShow.getBoundingClientRect().height
                slideShow.style.setProperty('--height',`${height}px`)
            }
        }
        window.addEventListener('resize',setProjectHeight)

        const scene = new Scene('projects')
        const enter =  scene.createTimeLine("#projects",{
            isEnter:true,
            functions: {
                onComplete: setProjectHeight,
                onReverseComplete: setProjectHeight
            }
        })
            .from("#projects .projects-slideshow .inner", {
                opacity: 0,
                y: "-20px",
                duration: 1,
            })

        const createSubScenes = () => {
            const projects = Array.from(document.querySelectorAll<HTMLElement>("#projects .project"))
            const stepsize = (window.innerWidth <1200 ? 33 : 50) / (projects.length -1)

            const timelines = []
            projects.forEach((p, i) => {
                if (i === 0) return
                scene.createTimeLine('#projects',{
                    isExit:false,
                    isEnter:false
                })
                    .to("#projects .projects-slideshow .inner", {
                        y: "-=" + 100/ projects.length + "%",
                        ease: "power2.inOut",
                        duration: 1.2
                    })
                    .to("#projects .animation-dot", {
                        top: "+=" + stepsize + "%",
                        ease: "power2.inOut",
                        duration: 1.2
                    }, "<")
            })
        }

        createSubScenes()
        const exit =scene.createTimeLine("#projects-exit", {
            isExit:true,
        })
            .to("#projects .animation-dot svg", midPointSvg)
            .to("#projects .animation-dot svg", {
                ...endPointSvg,
                color: "#FD8730",
            })
        return scene
    }



    const clientsBg = createClientsBg()
    function clients() {

        const scene = new Scene('clients',{
            onEnter:()=>{
                gsap.set("#clients .bg",{
                    filter: "blur(80px)",
                })
                clientsBg.play()
            },
            onExit:()=>{
                clientsBg.pause()
                gsap.set("#clients .bg",{
                    filter: "blur(0px)",
                })
            }
        })
        const enter = scene.createTimeLine("#clients", {
            isEnter:true,
        })
            .from("#clients .bg .wrap-right", {
                x: "100%",
                duration: .5
            })
            .from("#clients .bg .wrap-left", {
                x: "-100%",
                duration: .5
            }, "<")
            .from("#clients h2", {
                opacity: 0,
                duration: 0.5
            },"<")
            .from("#clients .logos-grid img", {
                opacity: 0,
                stagger: {
                    from: "center",
                    each: 0.1
                },
                ease: "power2.inOut"
            }, '<')
        const exit = scene.createTimeLine("#clients",{
            isExit:true
        })
            .to("#clients .animation-dot svg", midPointSvg)
            .to("#clients .animation-dot svg", {
               ...endPointSvg,
            })
        return scene
    }


    function seo() {
        const scene = new Scene('seo')
        const enter = scene.createTimeLine("#seo", {
            isEnter:true,
        })

            .from("#seo .wrapper", {
                opacity: 0,
                duration: .5,
            },">")

        const exit  = scene.createTimeLine('#seo-exit',{
            isExit:true
        })
            .to("#seo .animation-dot svg", midPointSvg)
            .to("#seo .animation-dot svg", endPointSvg)
            .to("#seo .animation-dot svg", {
                color: "#393939",
                duration: .3,
            }, "-=.8")

        return scene
    }

    // const seoScene = seo()

        const contactBg = auroraBAnimation( "#contact-us .bg")
    function contactUs() {
        const scene = new Scene('contact-us')
        const enter =   scene.createTimeLine("#contact-us", {
            isEnter:true,
            onStart: () => {
                gsap.set("#contact-us .bg",{
                    filter: "blur(80px)",
                })
                contactBg.resume()
            },
            functions: {
                onReverseComplete: () => {
                    contactBg.pause()
                    gsap.set("#contact-us .bg",{
                        filter: "blur(0px)",
                    })
                }
            }
        })

            .from("#contact-us .bg", {
                opacity: 0,
                x: "-100%",
                duration: .5,
            })
            .from("#contact-us .wrapper", {
                opacity: 0,
                duration: .5,
            }, "-=.3")

        return scene
    }



    const scenesManager = new ScenesManager()
    let wheelListener = null

    function generateScenes(startingPoint: string){
        scenesManager.scenes = [welcome(),intro(),expertise(),projects(),clients(),contactUs()];
        wheelListener = document.addEventListener("wheel", (e) => {
            if(scenesManager.busy || menuTimeLineController.isActive()) {
                return
            }
            if (e.deltaY > 0) {
                scenesManager.down()
            } else {
                scenesManager.up()
            }

        })
        if(startingPoint){
            scenesManager.startFrom(startingPoint)
        }
    }

    function setModeByScreenSize(startingPoint?:string){
        if(window.innerWidth > MAX_MOBILE_WIDTH){
            generateScenes(startingPoint)
        }else{
            //kill wheel listener
            if(wheelListener){
                document.removeEventListener("wheel",wheelListener)
            }
            turnOnBgAnimations()
            listenToSectionIntersections()
        }
    }
     function listenToSectionIntersections(){
        // we need to change the contrast of the header and contact btn based on the contrast of the section.
        const sections = Array.from(document.querySelectorAll<HTMLElement>('section[data-index]'))

         function isPointInRect(point:number, rect:DOMRect){
             // we only consider the y axis.
                return point >= rect.top && point <= rect.bottom
         }

         const headerMidPoint = ()=>{
            const rect = document.querySelector<HTMLElement>('header .logo').getBoundingClientRect()
            return rect.top + (rect.height/2)
         }

         const footerMidPoint = ()=>{
            const rect = document.querySelector<HTMLElement>('#contact-btn').getBoundingClientRect()
            return rect.top + (rect.height/2)
         }


        const observer = new IntersectionObserver((entries)=>{
            entries.forEach(entry=>{
                if(entry.isIntersecting){
                    const section = entry.target
                    const footerColor = section.getAttribute('data-footer-color')
                    const headerColor = section.getAttribute('data-header-color')

                    if(isPointInRect(headerMidPoint(),section.getBoundingClientRect())){
                        if(headerColor){
                            document.body.style.setProperty('--header-color',headerColor)
                        }
                    }
                    if(isPointInRect(footerMidPoint(),section.getBoundingClientRect())){
                        if(footerColor){
                            document.body.style.setProperty('--contact-btn-color',footerColor)
                        }
                    }
                }
            })
        },{
            threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]
        })
        sections.forEach(s=>{
            observer.observe(s)
        })
     }

    function turnOnBgAnimations(){
        welcomeBg.play()
        clientsBg.play()
        contactBg.play()
    }

    window.addEventListener('DOMContentLoaded',()=>{
        const hash = window.location.hash
        let secctionId = 'welcome'
        if(hash !== ''){
            secctionId = getHashFromUrl(hash)
        }
        setModeByScreenSize(secctionId)
    })
    let prev = window.innerWidth
    window.addEventListener('resize',(ev)=>{
        // only change if we cross the threshold.
        if((prev <= MAX_MOBILE_WIDTH && window.innerWidth > MAX_MOBILE_WIDTH) || (prev > MAX_MOBILE_WIDTH && window.innerWidth <= MAX_MOBILE_WIDTH)){
           // reload the page.
            window.location.reload()
        }
        prev = window.innerWidth
    })

    // Expertise slider
    expertiseSlider()

    const sectionLinks = ()=>{
        const innerLinks = getInternalLinks()
        innerLinks.forEach(l=>{
            l.addEventListener('click',async (e)=>{
                menuTimeLineController.reverse()
                if(isDesktopMode()){
                e.preventDefault()
                const id = getHashFromUrl(l.href)
                await scenesManager.goto(id)
                }
            })
        })
    }
    sectionLinks()
}
home();


function isDesktopMode(){
    return window.innerWidth > MAX_MOBILE_WIDTH
}
