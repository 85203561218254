import {gsap} from "gsap";
import {auroraBAnimation} from "./gsapSetups";

function menuTl() {
    const menuBg = auroraBAnimation( ".menu-open .bg")
    return gsap.timeline({
        paused: true,
        onStart: () => {
            gsap.set(".menu-open .bg", {
                filter: "blur(80px)",
            })
            menuBg.resume()
            //freeze the scroll on page.
            document.body.style.overflow = "hidden"
            document.body.classList.add("menu-is-opened")
        },
        onReverseComplete: () => {
            menuBg.pause()
            gsap.set(".menu-open .bg", {
                filter: "blur(0px)",
            })
            //unfreeze the scroll on page.
            document.body.style.overflow = "auto"
            document.body.classList.remove("menu-is-opened")
        }
    })
        .to("header .animation-dot", {
            visibility: "visible",
            duration: 0,
        })
        .from(".menu-open .top", {
            opacity: 0,
            duration: .1,
        })
        .to("header .animation-dot svg", {
            scale: 5,
            duration: 0.3,
        })
        .to("header .animation-dot svg", {
            scale: 150,
            duration: 1,
        })
        .to(".menu-open", {
            visibility: "visible",
            duration: 0,
        })
        .from(".menu-open .bg", {
            opacity: 0,
            duration: .5,
        })
        .from(".menu-items li", {
            opacity: 0,
            stagger: {
                each: 0.2,
            },
            duration: 1,
        })

        .timeScale(3)
}
export const menuTimeLineController = menuTl()
const menuTimeline = () => {
    const menu_open = document.querySelector<HTMLButtonElement>(".menu-button")
    const tl = menuTimeLineController;
    if (menu_open) {
        menu_open.addEventListener("click", () => {
            tl.play()
        })
    } else {
        console.error("menu button not found")
    }
    const menu_close = document.querySelector<HTMLButtonElement>(".menu-close")
    if (menu_close) {
        menu_close.addEventListener("click", () => {
            tl.reverse()
        })
    } else {
        console.error("menu close button not found")
    }

}
menuTimeline()


function footerIntersectionObserver(){
    // our footer element changes the contact-us button colors.
    // we need to check when it comes into view and when it leaves.
    const footer = document.querySelector("footer")
    const contactUsButton = document.querySelector(".contact-btn")
    if (!footer || !contactUsButton) {
        console.error("footer or contact us button not found")
        return
    }
    // the threshhold in this case is the height of the contact us button.
    // the contact us button is positioned at the bottom of the screen.
    // when the footer is overlapping 50% of the contact us button, we change the button colors.
    const screenHeight = window.innerHeight
    //margin from the bottom of the screen is equal to half the height of the contact us button.
    const margin = (contactUsButton.getBoundingClientRect().height / 2 )
    const options = {
        rootMargin: `-${margin}px`,
        threshold: [0,0.1,0.2,0.3]
    }
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if(entry.intersectionRatio > 0.3) {
                    document.body.classList.add("footer-fully-in-view")
                } else {
                    document.body.classList.remove("footer-fully-in-view")
                }
                document.body.classList.add("footer-in-view")
            } else {
                document.body.classList.remove("footer-in-view")
            }
        })
    }, options)
    observer.observe(footer)

}

function observeHeader(){
    const header = document.querySelector("header")
    if(header){
        const observer = new IntersectionObserver(([e]) => {
            e.target.classList.toggle("is-pinned", e.intersectionRatio < 1)
        },{threshold: [1]})
        observer.observe(header)
    }

}

function expandables(){
    const expandableElements = Array.from(document.querySelectorAll<HTMLButtonElement>(".expandable-trigger"))
    for (const element of expandableElements) {
        // lets find the rest of the elements that are related to this trigger.
        const controls  = element.getAttribute("aria-controls")
        if (!controls) {
            console.error("no aria-controls attribute found")
            return
        }
        const expandableContent = document.getElementById(controls)

        //optional attributes
        const showOnCollapsed = element.getAttribute("data-show-on-collapsed")
        const collapsedContent = document.getElementById(showOnCollapsed || "")

        if (!expandableContent) {
            console.error("expandable content not found")
            return
        }

        const  onToggle = ()=>{
            const open = element.getAttribute("aria-expanded")
            if(open === "true"){
                element.setAttribute("aria-expanded", "false")
                expandableContent.setAttribute("aria-hidden", "true")
                if (collapsedContent) {
                    collapsedContent.setAttribute("aria-hidden", "false")
                }
            }else{
                element.setAttribute("aria-expanded", "true")
                expandableContent.setAttribute("aria-hidden", "false")
                if (collapsedContent) {
                    collapsedContent.setAttribute("aria-hidden", "true")
                }
            }
        }

        element.addEventListener("click", onToggle)


    }

}



window.addEventListener("load", () => {
    footerIntersectionObserver()
    observeHeader()
    expandables()
})
