import {gsap} from "gsap";

type TimeLine = gsap.core.Timeline;
type TlConfig = {
    isEnter?: boolean;
    isExit?: boolean;
}

export class Scene {
    public readonly id: string;
    timeLines: TimeLine[] = []
    index:number =0;

    onCompletePromiseResolve?: (value: (PromiseLike<TlConfig> | TlConfig)) => void;
    onReverseCompletePromise?: (value: (PromiseLike<TlConfig> | TlConfig)) => void;

    constructor(id: string,public readonly config?:{
        onEnter?:()=>void,
        onExit?:()=>void
    }) {
        this.id = id;

    }

    public play():Promise<TlConfig|void>{

        const tl = this.timeLines[this.index]
        if(this.isAboutToExit && !this.isFullyInView()){
            return Promise.resolve()
        }
        if(tl){
            return new Promise<TlConfig>((resolve)=>{
                this.onCompletePromiseResolve = resolve
                tl.play()
                this.index++
            })
        }
        return Promise.resolve();
    }

    private get isAboutToExit(){
        return this.index === this.timeLines.length -1 && this.timeLines.length > 1
    }

    private isFullyInView(){
        //check if the bottom part of the scene element is in view.
        const section = this.getElement()
        if(section){
            const rect = section.getBoundingClientRect()
            //stupid bypass for now, need to fix this.
            return (rect.bottom - 2 )<= window.innerHeight
        }
    }

    public reverse():Promise<TlConfig|void>{
        const tl = this.timeLines[this.index -1]
        if(this.isAtStart() && !this.topOfSceneIsInView()){
            return Promise.resolve()
        }
        if(tl){
            return new Promise<TlConfig>((resolve)=>{
                tl.reverse()
                this.onReverseCompletePromise = resolve
                this.index--
            })
        }
        return Promise.resolve();
    }

    private isAtStart(){
        return this.index -1 === 0
    }

    private topOfSceneIsInView(){
        const section = this.getElement()
        if(section){
            const rect = section.getBoundingClientRect()
            return rect.top >= 0
        }
    }

    public enter(){
        return this.reset().play()
    }

    public exit(dir: "up" | "down" = "down"){
        const targetIndex = dir === "down"? (this.timeLines.length -1) : 1;
        this.index = targetIndex
        if(dir==="down"){
            return this.play()
        }else{
            return this.reverse()
        }
    }



    public createTimeLine(id:string,config: Record<string, any> & {
        isEnter?: boolean,
        isExit?: boolean,
        functions?:{
            onReverseComplete?:()=>void,
            onComplete?:()=>void
        }
    }):TimeLine{
        const tl =  gsap.timeline({
            paused: true,
            ...config?.functions,
            ...config,
            onStart: () => {
                config?.onStart?.()
            },
            onReverseComplete: () => {
                // resolve the promise.
                if(this.onReverseCompletePromise){
                    this.onReverseCompletePromise({isEnter:config.isEnter,isExit:config.isExit})
                    this.onCompletePromiseResolve = undefined
                }
                config?.functions?.onReverseComplete?.()
            },
            onComplete: () => {
                if(this.onCompletePromiseResolve){
                    this.onCompletePromiseResolve({isEnter:config.isEnter,isExit:config.isExit})
                    this.onCompletePromiseResolve = undefined
                }
                config?.functions?.onComplete?.()
            },
        });
        tl.timeScale(1.6)
        this.addTimeLines(tl)
        return tl;
    }

    public addTimeLines(...timelines:TimeLine[]){
        this.timeLines.push(...timelines)
    }

    getElement() {
        return document.querySelector<HTMLElement>(`#${this.id}`)
    }

    public reset(){
        this.timeLines.forEach(t=>t.pause(0,true))
        this.index = 0
        return this
    }

    public setEndState() {
        this.timeLines.forEach((t) => t.progress( 1,true))
        this.index = this.timeLines.length
    }
}


export class ScenesManager {
    scenes : Scene[]
    activeSceneIndex: number = 0;
    busy: boolean = false;

    async down(){
        if(this.activeSceneIndex < this.scenes.length) {
            this.busy = true;
            const config = await this.activeScene.play()
            if (config && config.isExit === true) {
                if(this.nextScene){
                    this.nextScene.reset()
                    this.onSceneChange(this.activeScene,this.nextScene)
                    this.activeSceneIndex++
                    await this.down()
                }
            }
            //set a timer to release the busy state.
            this.releaseBusy()
        }
    }

    async up(){
        this.busy = true
        const config = await this.activeScene.reverse()
        if(config && config.isEnter===true) {
            if (this.prevScene) {
                this.prevScene.setEndState()
                this.onSceneChange(this.activeScene,this.prevScene)
                this.activeSceneIndex--
                await this.up()
            }
        }
        this.releaseBusy()
    }


    get nextScene(){
        if(this.activeSceneIndex < this.scenes.length){
            return this.scenes[this.activeSceneIndex+1]
        }
    }

    get prevScene(){
        if(this.activeSceneIndex > 0 && this.scenes.length > 0){
            return this.scenes[this.activeSceneIndex-1]
        }
    }

    get activeScene(){
        return this.scenes[this.activeSceneIndex]
    }

    async goto(id: string) {
        const sIndex = this.findSceneIndex(id)
        if (sIndex > -1) {
            const dir = sIndex > this.activeSceneIndex ? "down" : "up";

            const nextScene = this.scenes[sIndex]
            nextScene.reset()

            await this.activeScene.exit(dir)
            this.onSceneChange(this.activeScene,nextScene)

            this.activeSceneIndex = sIndex

            await this.activeScene.play()

        }

    }

    private findSceneIndex(id):number{
        return this.scenes.findIndex(s=>s.id===id)
    }

    async startFrom(id: string) {
        // throw new Error('needs implementation.')
        const sIndex = this.findSceneIndex(id)
        if (sIndex > 0) {
            this.activeSceneIndex = sIndex
            this.lightsOn(this.activeScene)
            await this.activeScene.enter()
        }else{
            this.lightsOn(this.activeScene)
        }
    }

    onSceneChange(old:Scene,newS:Scene){
        this.lightsOut(old)
        this.lightsOn(newS)
    }

    lightsOut(s: Scene) {
        console.log('lights out scene',s.id,'🌛')
        const section = document.querySelector<HTMLElement>(`#${s.id}`)
        if (section) {
            section.classList.add('lights-out')
            section.classList.remove('lights-on')
        }
        if(s?.config?.onExit){
            s.config.onExit()
        }
    }
    lightsOn(s:Scene){
        console.log('lights on scene',s.id, "🌞")
        const section = s.getElement()
        section.classList.remove('lights-out')
        section.classList.add('lights-on')
        document.body.setAttribute('data-active-scene',s.id)
        window.location.hash = s.id
        if(s?.config?.onEnter){
            s.config.onEnter()
        }
    }

    public shutdown(){
        //set all scenes to be untouched.
        this.resetScenes(this.scenes)
    }

    private playScenesSilently(scenes: Scene[]) {
        scenes?.forEach(s =>{
            s.setEndState()
        })
    }

    private resetScenes(scenes: Scene[]){
        scenes?.forEach(s=>{
            s.reset()
        })
    }

    private releaseBusy(){
        setTimeout(()=>{
            this.busy = false
        },500)
    }
}
