import {gsap} from "gsap";

export function auroraBAnimation( baseElement = "#welcome .bg ") {
    const duration = 30;
    const ease = "none"
    const percent = "40%";
    return gsap.timeline({
        yoyo: true,
        repeat: -1,
        paused: true,
        defaults: {
            duration,
            ease,
            position: "absolute"
        }
    })
        .to(baseElement + " .poly1", {
            rotate: 360,
            bottom: percent,
            left: percent,
        })
        .to(baseElement + " .poly2", {
            rotate: 360,
            top: percent,
            left: percent,
        }, "<")

        .to(baseElement + " .poly3", {
            rotate: 360,
            top: percent,
            right: percent,
        }, "<")
        .to(baseElement + " .poly4", {
            rotate: 360,
            bottom: percent,
            right: percent,
        }, "<")

        .to(baseElement + " .poly5", {
            rotate: 360,
            bottom: percent,
            right: percent,
        }, "<")
}
